<template>
  <div class="card">
    <div class="card-header">
      <a-button class="mr-2" :disabled="!$auth.isLoggedIn()">
        <template #icon
          ><i class="fa fa-plus" @click="showModalNewDevTermEleven"
        /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="selectedRowKeys.length != 1 || !$auth.isLoggedIn()"
        @click="editEntity(selectedRows)"
      >
        <template #icon><i class="fa fa-pencil" /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="selectedRowKeys.length == 0 || !$auth.isLoggedIn()"
        @click="deleteEntity(selectedRowKeys)"
      >
        <template #icon><i class="fa fa-trash" /></template>
      </a-button>
      <a-upload
        class="mr-2"
        :file-list="fileList"
        :remove="handleRemove"
        :before-upload="beforeUpload"
      >
        <a-button>
          <upload-outlined></upload-outlined>
          Выберите файл
        </a-button>
      </a-upload>
      <a-button
        type="primary"
        :disabled="fileList.length === 0"
        :loading="uploading"
        style="margin-top: 16px"
        @click="handleUpload"
      >
        {{ uploading ? "Загружаю" : "Начать загрузку" }}
      </a-button>
    </div>

    <a-table
      :dataSource="gridData"
      :columns="gridColumns"
      :scroll="{ x: 'calc(100%)', y: '100%' }"
      :customRow="rowClick"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      rowKey="id"
    />

    <a-modal
      width="100"
      v-model:visible="visibleDevTermEleven"
      title="Наименование"
      @ok="handleOkDevTermEleven"
    >
      <a-form ref="programForm" :model="DevTermElevenFormState">
        <a-form-item label="Наименование">
          <a-input
            v-model:value="DevTermElevenFormState.DevTermElevenTitle"
            :disabled="!$auth.isLoggedIn()"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import { UploadOutlined } from "@ant-design/icons-vue";
import { ref } from "vue";
import request from "umi-request";
import { message } from "ant-design-vue";

export default {
  name: "DevTermEleven",
  components: {
    UploadOutlined,
  },
  data() {
    return {
      gridColumns: [
        { dataIndex: "id", title: "ID" },
        { dataIndex: "title", title: "Наименование" },
      ],
      gridData: [],
      visibleDevTermEleven: false,
      thisDevTermEleven: [],
      DevTermElevenFormState: {
        DevTermElevenID: undefined,
        DevTermElevenTitle: undefined,
      },
      selectedRowKeys: [],
      selectedRows: [],
      fileList: [],
      uploading: ref(false),
    };
  },
  methods: {
    async reload() {
      this.gridData = (await axios.get("developmentTermEleven")).data;
    },
    rowClick(record, index) {
      return {
        onClick: () => {
          console.log(record, index, 1111);
          this.thisDevTermEleven = record;
          this.DevTermElevenFormState.DevTermElevenTitle = record.title;
          this.DevTermElevenFormState.DevTermElevenID = record.id;
          console.log(this.thisProgram);
          this.showModalDevTermEleven();
        },
      };
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
    },
    showModalDevTermEleven() {
      this.visibleDevTermEleven = true;
    },
    showModalNewDevTermEleven() {
      this.DevTermElevenFormState.DevTermElevenID = undefined;
      this.DevTermElevenFormState.DevTermElevenTitle = undefined;
      this.visibleDevTermEleven = true;
    },
    async handleOkDevTermEleven() {
      console.log("DevTermElevenModal.");
      this.visibleDevTermEleven = false;
      if (this.DevTermElevenFormState.DevTermElevenTitle !== undefined) {
        if (this.DevTermElevenFormState.DevTermElevenID == undefined) {
          try {
            await axios.post("developmentTermEleven", {
              title: this.DevTermElevenFormState.DevTermElevenTitle,
            });
          } finally {
            this.DevTermElevenFormState.DevTermElevenID = undefined;
            this.DevTermElevenFormState.DevTermElevenTitle = undefined;
            await this.reload();
          }
        } else {
          console.log("DevTermElevenModal.");
          console.log({
            id: this.DevTermElevenFormState.DevTermElevenID,
            title: this.DevTermElevenFormState.DevTermElevenTitle,
          });
          try {
            await axios.put("developmentTermEleven", {
              id: this.DevTermElevenFormState.DevTermElevenID,
              title: this.DevTermElevenFormState.DevTermElevenTitle,
            });
          } finally {
            this.DevTermElevenFormState.DevTermElevenID = undefined;
            this.DevTermElevenFormState.DevTermElevenTitle = undefined;
            await this.reload();
          }
        }
      } else {
        return;
      }
    },
    async deleteEntity(selectedRowKeys) {
      console.log(selectedRowKeys);
      try {
        await axios.delete("developmentTermEleven", {
          headers: { "Content-Type": "application/json; charset=utf-8" },
          data: selectedRowKeys,
        });
      } finally {
        selectedRowKeys = undefined;
        await this.reload();
      }
    },
    async editEntity(selectedRows) {
      console.log(selectedRows);
      this.DevTermElevenFormState.DevTermElevenID = selectedRows[0].id;
      this.DevTermElevenFormState.DevTermElevenTitle = selectedRows[0].title;
      this.showModalDevTermEleven();
    },
      handleRemove(file) {
    const index = this.fileList.indexOf(file);
    const newFileList = this.fileList.slice();
    newFileList.splice(index, 1);
    this.fileList = newFileList;
  },
  beforeUpload(file) {
    this.fileList.push(file);
    return false;
  },
  async handleUpload() {
    const formData = new FormData();
    this.fileList.forEach((file) => {
      formData.append("files[]", file);
    });
    this.uploading = true;
    request(axios.defaults.baseURL + "/Upload/DevelopmentTermEleven", {
      method: "post",
      data: formData,
    })
      .then(() => {
        this.fileList = [];
        this.uploading = false;
        message.success("Загрузка прошла успешно.");
        this.reload();
      })
      .catch(() => {
        this.uploading = false;
        message.error("Ошибка Загрузки.");
      });
  },
  },
  async created() {
    await this.reload();
  },
};
</script>
